.select-location-m-wrapper {
  font-family: "Gotham";
  font-style: normal;
  height: 100vh;
  max-height: 100%;
  /* position: relative; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}

.select-location-m-container {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
.select-location-m-header {
  background: #2d9140;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 8vh;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 12;
}

.select-location-m-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 16px;
}

.select-location-m-heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* White */

  color: #ffffff;
  margin-left: 11px;
}
.select-location-details-container {
  position: absolute;
  background: white;
  width: 100%;

  background: #ffffff;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px 8px 0px 0px;
}

.location-modal-location-m-search-box {
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  /* padding-top: 16px;
    padding-bottom: 16px; */
  padding: 13px 0 13px 0;
  background: #ffffff;
  /* Grey / 40 */

  border: 1px solid #a3a3a3;
  border-radius: 6px;

  /* caret-color: red; */
  outline: none;
  padding-left: 16px;
  width: 100%;
  display: flex;
  margin-bottom: 32px;
}

.select-location-details-sub-heading {
  color: #747474;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
  margin-top: 16px;
}

/* .location-modal-location-m-search-box {
  flex: 1;
  border: none;
} */

.location-modal-location-m-search-box:focus {
  /* border: none;
  outline: none; */
}
.location-modal-suggetions-container {
  flex-grow: 1;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .location-modal-search-input-container > input, */
.location-modal-location-m-search-box::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #747474;
  /* padding: 0;
    margin: 0; */
  margin-top: 13px;
  margin-bottom: 13px;
  /* padding-left: 16px; */
}

.location-modal-location-m-cta {
  /* padding: 13px 40.5px; */
  width: 100%;
  background: #2d9140;
  border-radius: 8px;
  color: white;
  border: none;
  padding-top: 13px;
  padding-bottom: 13px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
}
.location-search-suggesstion-m-container {
  /* position: absolute;
  top: 100%;
  left: 0;
  z-index: 11;
  margin-top: 8px; */
  width: 100%;
  border: 1px solid #96c8a0;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08));
  border-radius: 4px;
  margin-top: -30px;
  margin-bottom: 18px;
}
