.location-modal-heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.location-modal-content-wrapper {
  width: 96.8%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Gotham";
}

.location-modal-heading {
  /* font-family: "Gotham"; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}
.location-modal-close-btn {
  background: transparent;
  color: #074ee8;
  font-weight: 400;
  font-size: 14px;
  outline: unset;
  border: none;
  padding: 7px 12px 7px 12px;
}

.location-modal-hr {
  /* height: 1px; */
  border: 1px solid #e8e8e8;
  width: 100%;
  margin-top: 9px;
  margin-bottom: 8px;
}
.location-modal-sub-heading {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Grey / 60 */

  color: #747474;
}
/* location-modal-search-input-container > button */

.location-modal-start-shopping-btn-disabled {
  padding: 13px 40.5px;
  background: #96c8a0;
  border-radius: 6px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: white;
  border: none;
}

.location-modal-start-shopping-btn {
  padding: 13px 40.5px;
  background: #2d9140;
  border-radius: 6px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: white;
  border: none;
}

.location-modal-search-input-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 8px;
}

.location-modal-location-search-box {
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  /* padding-top: 16px;
  padding-bottom: 16px; */
  padding: 13px 0 13px 0;
  background: #ffffff;
  /* Grey / 40 */

  border: 1px solid #a3a3a3;
  border-radius: 6px;

  /* caret-color: red; */
  outline: none;
  padding-left: 16px;
  width: 100%;
  display: flex;
}

.location-modal-location-search-box > input {
  flex: 1;
  border: none;
}

.location-modal-location-search-box > input:focus {
  border: none;
  outline: none;
}
.location-modal-suggetions-container {
  flex-grow: 1;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .location-modal-search-input-container > input, */
.location-modal-location-search-box::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #747474;
  /* padding: 0;
  margin: 0; */
  margin-top: 13px;
  margin-bottom: 13px;
  /* padding-left: 16px; */
}

.location-search-suggestion {
  background: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #000000;
  border-radius: 0px;
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.active-suggestion {
  background-color: #eaf4ec;
}

.location-search-suggesstion-container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 11;
  margin-top: 8px;
  width: 100%;
  border: 1px solid #96c8a0;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08));
  border-radius: 4px;
}

.auto-detect-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.auto-detect-btn-wrapper > div {
  margin-right: 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-left: 8px;
}

.select-location-modal {
  max-width: 100vw;
  width: 70vw !important;
  top: 9px !important;
}
.modal-dialog {
  max-width: 100vw !important;
}

@media (max-width: 900px) {
  .select-location-modal {
    max-width: 100vw;
    width: 90vw !important;
    top: 9px !important;
  }
}
