.product-list-details-imgbox {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 7px;
}
.product-list-details-img {
  width: 200px;
}

.image-viewer{
  height: 280px;
}

.multiple-image-wrapper{
  height: 400px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-list-details-imgbox .swiper-slide {
  width: 50px;
  border: 1px solid #dddddd;
  border-radius: 7px;
  padding: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  padding-top: "100%";
  overflow: hidden;
}
.product-list-details-imgbox .swiper-slide-active {
  border: 1px solid #00983d;
}
.product-details-label {
  font-weight: 500;
  font-size: 22px;
  line-height: 38px;
  text-transform: capitalize;
  color: #000000;
}
.label-green {
  font-weight: bold;
  font-size: 25px;
  line-height: 38px;
  text-transform: uppercase;
  color: #00983d;
}
.rating-tag {
  background: #ff6c44;
  border-radius: 8px;
  padding: 3px 8px;
  color: white;
}
.rating-tag::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background: transparent url("../../assets/images/rating.svg") no-repeat center;
  margin-right: 3px;
}
.instock {
  font-weight: 500;
  font-size: 16px;
  line-height: 38px;
  text-transform: capitalize;
}
.instock::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
}
.instock.true::before {
  content: "";
  background: #3bb54a url("../../assets/images/tick.svg") no-repeat center;
}
.instock.false::before {
  content: "";
  background: #de3618 url("../../assets/images/tick.svg") no-repeat center;
}
.discount-tag {
  font-weight: bold;
  font-size: 13.216px;
  line-height: 15px;
  color: #ffffff;
  background-color: #ffb930;
  border-radius: 3px;
  padding: 6px;
}
.wallet-tag {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  padding: 6px 8px;
  background-color: #27ae60;
  border-radius: 8px;
}
.wallet-tag::before {
  content: "";
  background: transparent url("../../assets/images/wallet.svg") no-repeat center;
  background-size: 13px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
}
.product-details-small-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: capitalize;
}
.product-details-qty {
  padding: 10px 20px;
  background: #00983d;
  border-radius: 8px;
}
.product-details-qty-num {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #fff;
}
.product-details-flavour {
  cursor: pointer;
}
.product-details-flavour-img {
  width: 56px;
  height: 56px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 7px;
  background: transparent url("../../assets/images/flavour.png") no-repeat
    center;
  background-size: 34px;
}
.product-details-flavour-label {
  font-weight: 500;
  font-size: 12.8155px;
  line-height: 15px;
  color: #b2b2b2;
}
.product-details-flavour.active .product-details-flavour-img {
  border: 0.918012px solid #00983d;
}
.product-details-flavour.active .product-details-flavour-label {
  color: #00983d;
}
.product-details-size {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #bbbdc1;
  padding: 8px 16px;
  border: 1px solid #bbbdc1;
  border-radius: 8px;
  background-color: transparent;
}
.product-details-size.active {
  background-color: #00983d;
  color: white;
}
.product-details-button {
  font-weight: bold;
  font-size: 15.12px;
  line-height: 18px;
  padding: 15px 30px;
  border: 1.08px solid;
  border-radius: 4.32px;
}
.details-cart {
  background-color: transparent;
  color: #00983d;
}
.details-buy {
  background-color: #00983d;
  color: white;
}
.product-details-wishlist {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #00983d;
  background-color: transparent;

  /* background: transparent url('../../assets/images/wish-green.svg') no-repeat center; */
}
.product-details-wishlist.active {
  background-color: #00983d;
}
.product-details-wishlist.active svg path {
  fill: white;
}
.shipping-points {
  font-size: 14px;
  line-height: 16px;
  color: #111a2c;
}
@media only screen and (max-width: 768px) {
  .shipping-points {
    font-size: 16px;
  }
  .tab-description {
    font-size: 16px;
  }
}
.shipping-points::before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 5px;
}
.shipping-points.distance::before {
  background: transparent url("../../assets/images/distance.svg") no-repeat
    center;
  background-size: 20px;
}
.shipping-points.message::before {
  background: transparent url("../../assets/images/message.svg") no-repeat
    center;
  background-size: 20px;
}
.shipping-points.coupon::before {
  background: transparent url("../../assets/images/coupon.svg") no-repeat center;
  background-size: 20px;
}
.shipping-points.express::before {
  background: transparent url("../../assets/images/express.svg") no-repeat
    center;
  background-size: 20px;
}

.empty-product {
  padding: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-loader-animation {
  margin-top: 6%;
}
.no-image-preview {
  position: absolute;
  top: 50%;
  left: 20%;
  color: grey;
}
.out-of-stock {
  color: white;
  background-color: rgb(206, 206, 206);
  pointer-events: none;
}
.pdp-mob-addcart {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 8;
  padding: 10px;
}
.pdp-mob-addcart .product-details-button {
  padding: 8px 15px;
}
.pdp-mob-addcart .product-details-wishlist {
  width: 35px;
  height: 35px;
}
.pdp-mob-addcart .product-details-wishlist svg {
  width: 15px;
  height: 15px;
}

@media only screen and (max-width: 576px) {
  .multiple-image-wrapper{
    height: 300px;
  }
  .image-viewer{
    height: 180px;
  }
}
